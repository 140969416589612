<template>
  <div style="width: 2rem;height: 100%;overflow: hidden;" ref="wrapper">
    <div ref="data">
      <div v-for="(item, index) in data" :key="index"
           style="width: 1.52rem;height: 0.86rem;padding-left: 0.24rem;background: rgba(1, 19, 67, 0.5); border-radius: 0.05rem;padding-top: 0.1rem;margin: 0.1rem auto">
        <p style="color: #FFA800;font-weight: bold">{{ item.eduName }}</p>
        <p style="color: #FFA800;font-weight: bold;margin: 0.1rem 0;height: 0.16rem;line-height: 0.16rem">
          <img src="/static/images/student.png"
               style="width: 0.16rem;height: 0.16rem;display: block;float: left;margin-right: 0.06rem"/>
          <span style="color: #B1D9FF">学生：</span>
          <span style="color: #FFFFFF;font-weight: bold;font-size: 0.14rem">{{ item.currentStudentNum }}</span>
        </p>
        <p style="color: #FFA800;font-weight: bold;height: 0.16rem;line-height: 0.16rem">
          <img src="/static/images/teacher.png"
               style="width: 0.16rem;height: 0.16rem;display: block;float: left;margin-right: 0.06rem"/>
          <span style="color: #B1D9FF">教职：</span>
          <span style="color: #FFFFFF;font-weight: bold;font-size: 0.14rem">{{ item.currentTeacherNum }}</span>
        </p>
      </div>
    </div>
    <div ref="copy"></div>
  </div>
</template>
<script>
export default {

  props: {
    data: {
      type: Array,
      default () {
        return []
      }
    }
  },

  data () {
    return {
      timer: null
    }
  },

  mounted () {
    setTimeout(() => {
      this.startMarquee()
    }, 100)
  },

  watch: {
    data () {
      setTimeout(() => {
        this.startMarquee()
      }, 100)
    }
  },

  methods: {

    startMarquee () {
      const wrapper = this.$refs.wrapper
      const data = this.$refs.data
      if (data.offsetHeight < wrapper.offsetHeight) return
      const copy = this.$refs.copy
      copy.innerHTML = data.innerHTML
      wrapper.scrollTop = wrapper.scrollHeight
      clearInterval(this.timer)
      this.timer = setInterval(() => {
        // if (data.offsetTop - wrapper.scrollTop >= 0)
        //   wrapper.scrollTop += copy.offsetHeight
        // else {
        //   wrapper.scrollTop--
        // }
        if (copy.offsetTop - wrapper.scrollTop <= 0) { wrapper.scrollTop -= data.offsetHeight } else {
          wrapper.scrollTop++
        }
      }, 60)
    }
  }
}
</script>
